export default [
  {
    name: "cdm",
    path: "cdm",
    component: () => import("./cdm.vue"),
    redirect: { name: "cdm:download" },
    children: [
      {
        name: "cdm:download",
        path: "download",
        component: () => import("./download-cdm.vue"),
      },

      {
        name: "cdm:notes",
        path: "notes",
        component: () => import("./cdm-notes.vue"),
      },
    ],
  },
];
