<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-8">
        <greeting></greeting>
      </div>
      <div class="col-sm-4">
        <latest-release></latest-release>
        <management-station></management-station>
        <local-weka-home></local-weka-home>
        <free-trial-status></free-trial-status>
        <entitlement-summary></entitlement-summary>
      </div>
    </div>
  </div>
</template>

<script>
 import LocalWekaHome      from './local-weka-home.vue';
 import Greeting           from './greeting.vue'
 import LatestRelease      from './latest-release.vue'
 import ManagementStation  from './management-station.vue'
 import FreeTrialStatus    from './free-trial-status.vue'
 import EntitlementSummary from './entitlement-summary.vue'

 export default {
     name: 'dashboard',

     components: {
         LocalWekaHome,
         Greeting,
         LatestRelease,
         FreeTrialStatus,
         EntitlementSummary,
         ManagementStation,
     }
 }
</script>
