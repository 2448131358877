import Vue                 from 'vue'
import { StatusIndicator } from 'vue-status-indicator'
import ErrorBox            from './error-box.vue'
import WekaSpinner         from './weka-spinner.vue'
import Intercom            from './intercom.vue'
import Modal               from './modal.vue'
import PaginatedTable      from './paginated-table.vue'
import Recaptcha           from './recaptcha.vue'
import User                from './user.vue'
import Organization        from './organization.vue'
import ClusterRow          from './cluster-row.vue'
import Cluster             from './cluster.vue'
import PaymentMethod       from './payment-method.vue'
import Entitlement         from './entitlement.vue'
import LicenseDetails      from './license-details.vue'
import ClusterGuidInput    from './cluster-guid-input.vue'
import UsageReport         from './usage-report.vue'

Vue.component(StatusIndicator.name  , StatusIndicator)
Vue.component(ErrorBox.name         , ErrorBox)
Vue.component(WekaSpinner.name      , WekaSpinner)
Vue.component(Intercom.name         , Intercom)
Vue.component(Modal.name            , Modal)
Vue.component(PaginatedTable.name   , PaginatedTable)
Vue.component(Recaptcha.name        , Recaptcha)
Vue.component(User.name             , User)
Vue.component(Organization.name     , Organization)
Vue.component(ClusterRow.name       , ClusterRow)
Vue.component(Cluster.name          , Cluster)
Vue.component(PaymentMethod.name    , PaymentMethod)
Vue.component(Entitlement.name      , Entitlement)
Vue.component(LicenseDetails.name   , LicenseDetails)
Vue.component(ClusterGuidInput.name , ClusterGuidInput)
Vue.component(UsageReport.name      , UsageReport)
