<template>
  <div>
    <span v-if="loading" style="color: #aaa">
      <samp class="small">{{ clusterId }}</samp>
      <weka-spinner :inline="true" />
    </span>
    <table v-else style="width: 100%">
      <tr>
        <td>
          <div style="line-height: 140%">
            <div>
              <span v-if="showOrg">
                <organization :id="clusterObj.org_id"></organization>
                <font-awesome-icon
                  :icon="['fas', 'fa-angle-right']"
                  class="fa-fw"
                  style="color: #aaa"
                ></font-awesome-icon>
              </span>
              <font-awesome-icon
                :icon="['fas', 'fa-cubes']"
                class="fa-fw"
              ></font-awesome-icon>
              {{ clusterObj.name }}
              <span style="color: #aaa">
                (<samp class="small">{{ clusterObj.id }}</samp
                >)
              </span>
              <span
                v-if="
                  clusterObj.license_status &&
                  clusterObj.license_status !== 'Valid'
                "
                class="label label-danger"
              >
                License {{ clusterObj.license_status }}
              </span>
            </div>
            <div style="font-size: 0.8em; color: #777">
              v{{ usageReport.version() }}
              <span style="color: #ccc">&nbsp;/&nbsp;</span>
              {{ usageReport.hostsCount }} hosts
                <span v-if='usageReport.format != 2'>
                    <span  style="color: #ccc">&nbsp;/&nbsp;</span>
                    {{ usageReport.ssdCapacity() | prettyBytes }} SSD capacity
                </span>
            </div>
            <div
              v-if="showSfAssetId && clusterObj.sf_asset_id"
              style="font-size: 0.8em; color: #777"
            >
              SF Asset Id {{ clusterObj.sf_asset_id }}
            </div>
            <div style="font-size: 0.8em; color: #999">
              <span v-if="clusterObj.last_seen">
                <status-indicator v-if="isAlive" positive></status-indicator>
                <status-indicator v-else></status-indicator>
                Last seen {{ clusterObj.last_seen | moment("from") }}
              </span>
              <span v-if="hasBirthdayThisWeek">
                <span style="color: #ddd">&nbsp;|&nbsp;</span>
                <strong>
                  <span v-if="hasBirthdayToday" style="color: #6d8cd1">
                    🎉 Today is this cluster's birthday!
                  </span>
                  <span v-else> 🎉 This week is this cluster's birthday! </span>
                </strong>
              </span>
            </div>
          </div>
        </td>
        <td class="text-right" style="vertical-align: middle">
          <span
            v-if="usageReport.alertCount()"
            class="badge"
            style="background-color: #d9534f"
          >
            {{ usageReport.alertCount() }}
          </span>
        </td>
        <td v-if="isLink" class="text-right" style="vertical-align: middle">
          <font-awesome-icon
            :icon="['fas', 'fa-angle-right']"
            class="fa-fw"
          ></font-awesome-icon>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import moment from "moment";
import { UsageReportParser } from "../backend/usage-reports";

export default {
  name: "cluster-row",

  props: {
    clusterId: {
      type: String,
      default: null,
    },
    cluster: {
      type: Object,
    },
    showOrg: {
      type: Boolean,
      default: false,
    },
    showSfAssetId: {
      type: Boolean,
      default: false,
    },
    isLink: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      clusterObj: null,
      usageReport: null,
    };
  },

  computed: {
    isAlive() {
      return (
        this.clusterObj &&
        this.clusterObj.last_seen &&
        moment().diff(moment(this.clusterObj.last_seen), "minutes") < 180
      );
    },
    hasBirthdayThisWeek() {
      const now = moment();
      return (
        this.isAlive &&
        this.clusterObj.created_at &&
        moment(this.clusterObj.created_at).year() !== now.year() &&
        moment(this.clusterObj.created_at).year(now.year()).isSame(now, "week")
      );
    },
    hasBirthdayToday() {
      const now = moment();
      return (
        this.hasBirthdayThisWeek &&
        moment(this.clusterObj.created_at).year(now.year()).isSame(now, "day")
      );
    },
  },

  async mounted() {
    this.clusterObj = this.cluster
      ? this.cluster
      : await this.$store.dispatch("getObject", {
          collection: "system",
          pk: this.clusterId,
        });
    this.usageReport = new UsageReportParser(
      this.clusterObj ? this.clusterObj.last_usage_report : null
    );
    this.loading = false;
  },
};
</script>
