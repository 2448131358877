export default [
  {
    name: "account",
    path: "account",
    redirect: { name: "account:profile" },
    component: () => import("./main.vue"),
    children: [
      {
        name: "account:profile",
        path: "profile",
        component: () => import("./profile.vue"),
      },
      {
        name: "account:notifications",
        path: "notifications",
        component: () => import("./notifications/main.vue"),
      },
      {
        name: "account:api-tokens",
        path: "api-tokens",
        component: () => import("./api-tokens/main.vue"),
      },
      {
        name: "account:team",
        path: "team",
        component: () => import("./team/main.vue"),
      },
      {
        name: "account:misc",
        path: "misc",
        component: () => import("./misc.vue"),
      },
    ],
  },
];
