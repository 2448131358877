export default [
  {
    name: "login",
    path: "/login",
    component: () => import("./login.vue"),
  },
  {
    name: "pending",
    path: "/pending",
    component: () => import("./pending.vue"),
  },
  {
    name: "first-user",
    path: "/login/first-user",
    component: () => import("./first-user.vue"),
  },
  {
    name: "register-start",
    path: "/login/register",
    component: () => import("./register-start.vue"),
  },
  {
    name: "register-finish",
    path: "/login/register/:token",
    component: () => import("./register-finish.vue"),
  },
  {
    name: "password-reset-start",
    path: "/login/password-reset",
    component: () => import("./password-reset-start.vue"),
  },
  {
    name: "password-reset-finish",
    path: "/login/password-reset/:token",
    component: () => import("./password-reset-finish.vue"),
  },
  {
    name: "password-setup",
    path: "/login/password-setup/:token",
    component: () => import("./password-setup.vue"),
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("./logout.vue"),
  },
  {
    name: "oauth",
    path: "/oauth",
    component: () => import("./oauth.vue"),
  },
  {
    name: "aws-register",
    path: "/register/aws/:token",
    component: () => import("./aws-register.vue"),
  },
];
