<template>
  <div>
    <top-bar ref="topBar"></top-bar>
    <div style="padding:1rem;">
    </div>
    <router-view></router-view>
    <intercom v-if="intercomSettings"
              :app-id="intercomSettings.app_id"
              :user-id="curUser.id"
              :email="curUser.emails[0]"
              :name="curUser.display_name"
              :user-hash="intercomSettings.user_hash"
              :created-at="curUserJoinDate">
    </intercom>
  </div>
</template>

<script>
 import moment from 'moment'
 import TopBar from './top-bar.vue'

 export default {
     name: 'root',

     metaInfo: {
         title: 'WekaIO',
     },

     components: {
         TopBar,
     },

     computed: {
         curUser() {
             return this.$store.getters.currentUser
         },
         curUserJoinDate() {
             return moment(this.curUser.created_at).unix()
         },
         intercomSettings() {
             if (this.curUser && this.curUser.integrations) {
                 return this.curUser.integrations.intercom
             }
             return null
         }
     }
 }
</script>
