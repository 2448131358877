<!-- Adopted from https://github.com/Morgul/vueboot/blob/master/src/modal/modal.vue and adapted for Vuejs2 -->

<template>
  <div class="modal" :class="{ fade: animation }" tabindex="-1" role="dialog" aria-hidden="true"
       :data-backdrop="backdrop.toString()" :data-keyboard="keyboard.toString()">
    <div class="modal-dialog" :class="modalClass" role="document" :style="{ 'max-width': width, width: width }">
      <form @submit.prevent="$emit('done')" v-if="show">
        <div class="modal-content">
          <div class="modal-header" v-if="header">
            <slot name="header"></slot>
          </div>
          <slot name="outer-body">
            <div class="modal-body">
              <slot name="body"></slot>
            </div>
          </slot>
          <div class="modal-footer" v-if="footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
 export default {
     name: 'modal',
     props: {
         animation: {
             type: Boolean,
             default: true
         },
         header: {
             type: Boolean,
             default: true
         },
         footer: {
             type: Boolean,
             default: true
         },
         width: {
             type: String
         },
         backdrop: {
             default: true
         },
         keyboard: {
             type: Boolean,
             default: true
         },
         autoFocus: {
             type: String
         },
         modalClass: {
             type: String
         },
         onClosed: {
             type: Function,
             default: () => {}
         }
     },
     data() {
         return {
             show: false
         }
     },
     methods: {
         showModal() {
             this.show = true;
             $(this.$el).modal('show');
         },
         hideModal() {
             this.show = false;
             $(this.$el).modal('hide');
         },
         refresh() {
             $(this.$el).data('bs.modal').handleUpdate();
         },
         onShown() {
             if(this.autoFocus) {
                 var autoElem = $(this.autoFocus);
                 if(autoElem[0]) {
                     autoElem.focus();
                 }
             }
         },
         onHidden() {
             this.show = false
             this.onClosed()
         },
     },
     watch: {
         show() {
             if(this.show) {
                 this.showModal();
             } else {
                 this.hideModal();
             }
         }
     },
     mounted() {
         $(this.$el).on('shown.bs.modal', this.onShown)
         $(this.$el).on('hidden.bs.modal', this.onHidden)
     },
     beforeDestroy() {
         $(this.$el).off('shown.bs.modal', this.onShown)
         $(this.$el).off('hidden.bs.modal', this.onHidden)
     },
 }
</script>
