export default [
  {
    name: "licenses",
    path: "licenses",
    redirect: { name: "licensing:licenses" },
    component: () => import("./main.vue"),
    children: [
      {
        name: "licensing:licenses",
        path: "licenses",
        component: () => import("./classic/licenses.vue"),
      },
      {
        name: "licensing:create",
        path: "create",
        component: () => import("./classic/entitlements.vue"),
      },
      {
        name: "licensing:usage-reports",
        path: "usage-reports",
        component: () => import("./usage-reports/main.vue"),
      },
    ],
  },
];
