export default [
  {
    name: "wms",
    path: "wms",
    component: () => import("./wms.vue"),
    redirect: { name: "wms:download" },
    children: [
      {
        name: "wms:download",
        path: "download",
        component: () => import("./download-wms.vue"),
      },

      {
        name: "wms:notes",
        path: "notes",
        component: () => import("./wms-notes.vue"),
      },
    ],
  },
];
