export default [
  {
    name: "releases",
    path: "releases",
    component: () => import("./main.vue"),
    children: [
      {
        name: "releases:release",
        path: ":release",
        component: () => import("./release.vue"),
        redirect: { name: "releases:release:download" },
        children: [
          {
            name: "releases:release:download",
            path: "download",
            component: () => import("./download-release.vue"),
          },
          {
            name: "releases:release:install",
            path: "install",
            component: () => import("./install-release.vue"),
          },
          {
            name: "releases:release:notes",
            path: "notes",
            component: () => import("./release-notes.vue"),
          },
          {
            name: "releases:release:internal-notes",
            path: "internal-notes",
            component: () => import("./internal-notes.vue"),
          },
          {
            name: "releases:release:compatibility",
            path: "compatibility",
            component: () => import("./compatibility.vue"),
          },
          {
            name: "releases:release:packages",
            path: "packages",
            component: () => import("./packages.vue"),
          },
        ],
      },
    ],
  },
];
