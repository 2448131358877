export default [
  {
    name: "lwh",
    path: "lwh",
    component: () => import("./lwh.vue"),
    redirect: { name: "lwh:download" },
    children: [
      {
        name: "lwh:download",
        path: "download",
        component: () => import("./download-lwh.vue"),
      },

      {
        name: "lwh:notes",
        path: "notes",
        component: () => import("./lwh-notes.vue"),
      },
    ],
  },
];
